<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <b-card>
      <ValidationObserver
        ref="KnowledgeAddForm">
        <!-- Form -->
        <b-form
          class="p-2"
        >
          <b-row class="align-items-end">
            <b-col
              cols="12">
              <TextFieldInput
                v-model="form.name"
                :rules="{ required: false }"
                name="Name"
                label="Group Name"
                placeholder="Enter Name"
                trim
              />
            </b-col>
            <b-col
              cols="12">
              <SelectField
                v-model="form.allowGroup"
                :close-on-select="false"
                :deselect-from-dropdown="true"
                multiple
                :options="groupService"
                name="Group"
                form-label="Group"
                label-option="name"
                return-value="id"
              />
            </b-col>
          </b-row>
          <b-row class="align-items-end">
            <b-col
              cols="12">
              <TextFieldInput
                v-model="email"
                :rules="{ required: false }"
                name="Email"
                label="Enter Email"
                placeholder="Enter Email"
                trim
                @keydown.enter="addEmail(email)"
              />
            </b-col>
          </b-row>
          <div>
            <b-table
              striped
              hover
              responsive
              :fields="fields"
              :items="emails"
            >
              <template #cell(email)="{item}">
                {{ item.email || item }}
              </template>
              <template #cell(action)="{ index, item }">
                <b-button
                  size="sm"
                  variant="danger"
                  @click="removeEmail(index, item)">
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
          </div>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-5 mr-2 btn-submit"
              @click="updateEmailGroup"
            >
              Save
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SelectField from '@/components/Form/SelectField.vue'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import EmailGroupProvider from '@/resources/EmailGroupProvider'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import GroupProvider from '@/resources/GroupProvider'

const GroupService = new GroupProvider()

const EmailGroupService = new EmailGroupProvider()

export default {
  components: {
    TextFieldInput,
    FeatherIcon,
    SelectField
  },
  data () {
    return {
      form: {
        name: '',
        email: [],
        allowGroup: []
      },
      fields: [
        { key: 'email', label: 'Email' },
        { key: 'action', label: '' }
      ],
      email: '',
      emailArray: [],
      deleted: [],
      groupService: []
    }
  },
  computed: {
    emails () {
      const result = [...this.form.email, ...this.emailArray]
      return [...new Set(result)]
    },
    emailGroupId () {
      return this.$route.params?.id
    }
  },
  mounted () {
    this.getGroupService()
    this.getEmailGroupById(this.emailGroupId)
  },
  methods: {
    async updateEmailGroup () {
      try {
        const payload = {
          ...this.form,
          deleted: this.deleted
        }
        await EmailGroupService.update(this.emailGroupId, payload)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Email Group Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Email Group has been added'
          }
        })
        // this.$router.push('/e')
      } catch (err) {
        console.error(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Email Group has been Failed ${err}`
          }
        })
      }
    },
    async getGroupService () {
      try {
        const { data } = await GroupService.findAll(1, 999999)
        if (Array.isArray(data)) {
          this.groupService = data?.filter((item) => item?.status === 'active')
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getEmailGroupById (id) {
      try {
        const { data } = await EmailGroupService.findOne(id)
        const emailGroup = data?.emailGroup
        const emailCenter = data?.emailCenter
        if (emailGroup) {
          this.form.name = emailGroup?.name
          this.form.allowGroup = emailGroup?.allowGroup
        }
        if (Array.isArray(emailCenter) && emailCenter.length > 0) {
          this.emailArray = emailCenter.map((item) => ({
            id: item?.id,
            email: item?.email
          }))
        }
      } catch (error) {
        console.error(error)
      }
    },
    addEmail (email) {
      const parseEmail = this.parseEmail(email)
      if (parseEmail) {
        if (Array.isArray(parseEmail) && parseEmail.length > 0) {
          const result = []
          parseEmail.forEach((item) => {
            if (Array.isArray(this.emailArray) && this.emailArray.length > 0) {
              this.emailArray.forEach((item2) => {
                if (item2?.email !== item) {
                  result.push(item)
                }
              })
            } else {
              result.push(item)
            }
          })
          this.form.email.push(...result)
        }

        if (typeof parseEmail === 'string') {
          const isInculde = this.emailArray.includes(parseEmail)
          if (!isInculde) {
            this.form.email.push(parseEmail)
          }
        }
      }
      // this.form.email.push(email)
      this.form.email = [...new Set(this.form.email)]
      // const em = [...this.form.email, ...this.emailArray]
      // this.emailArray = [...new Set(em)]
      this.email = ''
    },
    removeEmail (index, data) {
      if (data?.id) {
        const findIndex = this.emailArray.findIndex((item) => item?.id === data?.id)
        if (findIndex >= 0) {
          this.deleted.push(this.emailArray[findIndex]?.id)
          this.emailArray.splice(findIndex, 1)
        }
      } else if (index >= 0 && Array.isArray(this.form.email)) {
        this.form.email.splice(index, 1)
      }
    },
    parseEmail (email) {
      if (email && typeof email === 'string') {
        const emailArray = email.split(/[;,]+/)
        if (Array.isArray(emailArray) && emailArray.length > 0) {
          const result = []
          emailArray.forEach((item) => {
            const isValidEmail = this.isValidEmail(item)
            if (isValidEmail) {
              result.push(item)
            }
          })
          return [...new Set(result)]
        }
        return email
      }
      return null
    },
    isValidEmail (email) {
      // Regular expression pattern to validate email address
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      // Return true if email matches pattern, false otherwise
      return emailPattern.test(email)
    }
  }
}
</script>
